<template>
  <v-data-table
    class="main-table rounded-0 dark-blue"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    calculate-widths
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="header"
    :items="filteredListOrgs"
    :server-items-length="filteredListOrgs ? filteredListOrgs.length : 0"
  >
    <template v-slot:item="{ item, index }">
      <tr @click="showSmallTable(item, index)" class="selected-table">
        <td
          v-for="(header, key) in HEADER_ORGANIZATIONS"
          class="dark-blue"
          :key="key"
          :class="{ primary: !flagNew && indexSelected === index }"
        >
          <span
            :style="{ height: '30px', border: 'none' }"
            class="ma-0 rounded-0"
          >
            {{ item[header.value] }}
          </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
export default {
  props: ["flagNew", "org_selected"],
  computed: {
    // styles() {
    //   return this.smallTable
    //     ? { margin: "0px", width: "25%", padding: "0px !important" }
    //     : { margin: "auto", width: "90%" };
    // },
    ...mapState({
      list_organizations: (state) =>
        state.moduleOrganizations.list_organizations,
    }),
    ...mapGetters({
      filteredListOrgs: "moduleOrganizations/filteredListOrgs",
    }),
    header() {
      return generateHeaderData(this.HEADER_ORGANIZATIONS);
    },
  },
  data() {
    return {
      HEADER_ORGANIZATIONS: [
        {
          text: "Name",
          value: "organization_descr",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Email",
          value: "primary_email",
          class: "table-header",
          sortable: true,
        },

        {
          text: "type",
          value: "organization_type",
          class: "table-header",
          sortable: true,
        },
      ],
      smallTable: false,
      indexSelected: -1,
    };
  },
  watch: {
    filteredListOrgs() {
      const indexList = this.filteredListOrgs.findIndex(
        (x) => x.organization_id === this.org_selected.organization_id,
      );

      this.indexSelected = indexList;
    },
  },
  methods: {
    showSmallTable(item, index) {
      // this.smallTable = true;

      const indexList = this.list_organizations.findIndex(
        (x) => x.organization_id === item.organization_id,
      );
      this.indexSelected = index;
      this.$emit("showSmallTable", { flag: false, index: indexList });
    },
  },
};
</script>

<style scoped lang="scss">
.w-50 {
  width: 90%;
  margin: auto;
}
</style>
